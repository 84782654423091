import React from 'react'
import './Hero.css'

const Hero = () => {
  return (
    <main>
        <section className="banner">
            <div className="top-fixed d-flex">
                <div className="info">
                    <label>+91 6370183009</label>
                    <label>situ@chinmayakumarbiswal.in</label>
                </div>
                <div className="social">
                    <a href="#/">
                        <a href="https://www.facebook.com/situ.chinmaya"><i className="fa fa-facebook"></i></a>
                        <a href="https://www.linkedin.com/in/chinmaya-kumar-biswal-16045"><i className="fa fa-linkedin"></i></a>
                        <a href="https://www.instagram.com/chinmaya.situ/"><i className="fa fa-instagram"></i></a>
                        <a href="https://github.com/chinmayakumarbiswal"><i className="fa fa-github"></i></a>
                        <a href="https://twitter.com/Chinmaya_situ?s=09"><i className="fa fa-twitter"></i></a>
                        <a href="https://api.whatsapp.com/send?phone=919556328216&text=hey"><i className="fa fa-whatsapp"></i></a>
                    </a>
                </div>
            </div>
            <div className="container">
                <div className="row full-screen align-items-center">
                    <div className="col-lg-7">
                        <div className="box">
                            <h6>Hello, My Name is</h6>
                            <h1>Chinmaya Kumar Biswal</h1>
                            <p className="lead">
                                Associate Devloper
                            </p>
                            <p className="desc">
                                I specialize in cloud technology for web-based applications, driven by a passion for innovation and a commitment to delivering high-quality solutions. With expertise in creating user-friendly experiences, I leverage cloud platforms to bring ideas to life. Let's collaborate and make something great together!
                            </p>
                            <div className="btn-bar">
                                <a href="https://www.canva.com/design/DAE9rw4s9zw/zJIjDsA6SRY8JRMsbpSNBQ/edit?utm_content=DAE9rw4s9zw&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton">Download CV</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="me"></div>
        </section>
    </main>
  )
}

export default Hero
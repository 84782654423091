import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
    const [values, setValues] = useState({
        name: '', email: '', subject: '', message: ''
    });

    const [lastInputTime, setLastInputTime] = useState(0);
    const inputDelay = 60000;

    const setName = name => {
        return ({ target: { value } }) => {
            setValues(oldValues => ({ ...oldValues, [name]: value }));
        }
    };

    const setEmail = email => {
        return ({ target: { value } }) => {
            setValues(oldValues => ({ ...oldValues, [email]: value }));
        }
    };

    const setSubject = subject => {
        return ({ target: { value } }) => {
            setValues(oldValues => ({ ...oldValues, [subject]: value }));
        }
    };

    const setMessage = message => {
        return ({ target: { value } }) => {
            setValues(oldValues => ({ ...oldValues, [message]: value }));
        }
    };

    const sendData = async () => {
        try {
            const authToken = '9b2f6a8d9c7e45f1a30b39e37d8c7aeb';
            const response = await fetch('https://asia-south1-portfolio-419611.cloudfunctions.net/sendEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'Access-Control-Allow-Origin': 'http://localhost:3001'
                },
                body: JSON.stringify(values)
            });

            if (!response.ok) {
                throw new Error(`Request failed with status ${response.status}`);
            }

            alert('Mail Sent successfully !');
            setValues({ name: '', email: '', subject: '', message: '' });
        } catch (error) {
            console.error(error);
            alert(`Mail send failed! ${error.message}`);
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault();

        const currentTime = Date.now();
        if (currentTime - lastInputTime < inputDelay) {
            alert("You are submitting too quickly. Please wait a moment before trying again.");
            return;
        }

        setLastInputTime(currentTime);
        await sendData();
    };

    return (
        <section id="messageme">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5" data-aos="zoom-in">
                        <div className="contact-info">
                            <h4>Get in touch</h4>
                            <p className="text-white">
                                Always available for freelancing if the right project comes along feel to
                                contact me.
                            </p>
                            <ul>
                                <li className="media">
                                    <i className="fa fa-map"></i>
                                    <span className="media-body">
                                        Chilika
                                    </span>
                                </li>
                                <li className="media">
                                    <i className="fa fa-envelope"></i>
                                    <span className="media-body">
                                        situ@chinmayakumarbiswal.in
                                    </span>
                                </li>
                                <li className="media">
                                    <i className="fa fa-phone-square"></i>
                                    <span className="media-phone">
                                        +91 6370183009
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-7" data-aos="zoom-in">
                        <div className="contact-form">
                            <h4>Say something</h4>
                            <form onSubmit={onSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="name" placeholder="Name" className="form-control" value={values.name} onChange={setName('name')} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="email" placeholder="Email*" className="form-control" value={values.email} onChange={setEmail('email')} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="subject" placeholder="subject*" className="form-control" value={values.subject} onChange={setSubject('subject')} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea name="Message" id="message" placeholder="Write Your Message*" rows="5" className="form-control" value={values.message} onChange={setMessage('message')}>

                                            </textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="send">
                                            <button className="btn btn-primary" type="submit" value="send" name="sendMail">Send Message</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="separated"></div>
            </div>
        </section>
    )
}

export default Contact;
